import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Router, RouterModule } from "@angular/router";
import { Conversation } from "@app/shared/models/entities/ai-teacher/Conversation";
import { FormsModule } from "@angular/forms";
import { ChatListItemComponent } from "@app/ai-teacher/components/chat-list-item/chat-list-item.component";

@Component({
  selector: "chat-list",
  standalone: true,
  templateUrl: "./chat-list.component.html",
  styleUrl: "./chat-list.component.scss",
  imports: [CommonModule, RouterModule, FormsModule, ChatListItemComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatListComponent {
  @Input() protected title: string = "";
  @Input() protected conversations: Conversation[] = [];
  @Input() protected shouldNavigateOnItemClick = true;
  @Input() protected useMobileLayout = false;

  @Output() conversationChange = new EventEmitter<Conversation>();
  @Output() archiveConversation = new EventEmitter<number>();

  protected selectedConversationId?: number;

  constructor(private readonly router: Router) {}

  protected onConversationClick(conversation: Conversation) {
    this.selectedConversationId = conversation.id;
    this.conversationChange.emit(conversation);
  }

  protected onUpdateConversation(conversation: Conversation) {
    this.conversations = this.conversations.map((item) => {
      if (item.id === conversation.id) {
        const updatedConversation = Conversation.Factory(conversation);
        Object.assign(updatedConversation, item, { title: conversation.title });
        return updatedConversation;
      }
      return item;
    });
  }

  protected onArchiveConversation(conversation: Conversation) {
    this.archiveConversation.emit(conversation.id);
    this.shouldNavigateOnItemClick && this.router.navigate(["gesprekken"]);
  }
}
