import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ButtonComponent, InputComponent } from "@app/shared/basic-components";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "chat-input",
  standalone: true,
  imports: [ButtonComponent, InputComponent],
  templateUrl: "./chat-input.component.html",
  styleUrl: "./chat-input.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatInputComponent {
  @Input() protected isDisabled = false;

  @Output() sendMessage = new EventEmitter<string>();

  protected formGroup = new FormGroup({
    message: new FormControl(""),
  });

  protected onSubmit() {
    const value = this.formGroup.get("message")?.value;

    if (value) {
      this.formGroup.reset();
      this.sendMessage.emit(value);
    }
  }
}
