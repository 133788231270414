<div class="d-flex justify-content-between align-items-center column-gap-2">
  @if (isEditMode) {
    <shared-input
      class="flex-grow-1"
      size="small"
      [group]="formGroup"
      controlName="title"
      [focus]="true"
      placeHolder=""
      (enterPress)="onTitleBlurInput(formGroup.get('title')?.value)"
      (blurred)="onTitleBlurInput($event.target.value)"
    />
  } @else {
    <div class="text-limit">{{ conversation.title }}</div>
    <i
      class="fa-solid fa-ellipsis"
      [ngbPopover]="actionsMenuTemplate"
      [popoverContext]="{ $implicit: conversation }"
      placement="right auto"
      (click)="$event.stopPropagation()"
    ></i>
  }
</div>

<ng-template #actionsMenuTemplate>
  <div class="d-flex flex-column row-gap-2">
    <shared-button
      text="Hernoemen"
      faIcon="fa-solid fa-pen"
      padding="no-padding"
      [style]="'link-blue'"
      (click)="$event.stopPropagation(); onRenameButtonClicked()"
    ></shared-button>

    <shared-button
      text="Verwijderen"
      faIcon="fa-solid fa-trash-can"
      padding="no-padding"
      [style]="'link-red'"
      (click)="$event.stopPropagation(); onDeleteButtonClicked()"
    ></shared-button>
  </div>
</ng-template>
