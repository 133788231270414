@if (title) {
  <div class="font-14 os-bold ms-3 mb-2 os-text-blue">{{ title }}</div>
}

<ul
  class="list-unstyled mb-0"
  [ngClass]="{
    'os-block-3': !useMobileLayout,
    'p-3': !useMobileLayout,
    'px-3': useMobileLayout,
  }"
>
  @for (conversation of conversations; track conversation) {
    <li
      class="px-3 py-2 chat-list-item"
      routerLinkActive="active"
      [routerLink]="shouldNavigateOnItemClick ? [conversation.id] : null"
      [class.active]="!shouldNavigateOnItemClick ? selectedConversationId === conversation.id : null"
      (click)="!shouldNavigateOnItemClick ? onConversationClick(conversation) : null"
    >
      <chat-list-item
        [conversation]="conversation"
        (updateConversation)="onUpdateConversation($event)"
        (archiveConversation)="onArchiveConversation($event)"
      ></chat-list-item>
    </li>
  }
</ul>
