import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Conversation } from "@app/shared/models/entities/ai-teacher/Conversation";

@Injectable({
  providedIn: "root",
})
export class AITeacherStore {
  private readonly _conversation = new BehaviorSubject<Conversation | undefined>(undefined);
  readonly conversation$ = this._conversation.asObservable();

  public set conversation(conversation: Conversation) {
    this._conversation.next(conversation);
  }
}
