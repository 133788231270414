import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Conversation } from "@app/shared/models/entities/ai-teacher/Conversation";
import { FormControl, FormGroup } from "@angular/forms";
import { ButtonComponent, InputComponent } from "@app/shared/basic-components";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { AITeacherService } from "@app/shared/services/ai-teacher.service";
import { ConversationStatus } from "@app/shared/enums/conversation-status-enum";

@Component({
  selector: "chat-list-item",
  standalone: true,
  templateUrl: "./chat-list-item.component.html",
  styleUrl: "./chat-list-item.component.scss",
  imports: [ButtonComponent, NgbPopover, InputComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatListItemComponent {
  @Input() protected conversation!: Conversation;

  @Output() updateConversation = new EventEmitter<Conversation>();
  @Output() archiveConversation = new EventEmitter<Conversation>();

  protected isEditMode = false;
  protected formGroup = new FormGroup({
    title: new FormControl(""),
  });

  constructor(private readonly aiTeacherService: AITeacherService) {}

  protected onRenameButtonClicked() {
    this.isEditMode = true;

    this.formGroup.patchValue({
      title: this.conversation.title,
    });
  }

  protected onDeleteButtonClicked() {
    this.aiTeacherService.updateConversation(this.conversation.id, { status: ConversationStatus.ARCHIVED }).subscribe((conversation) => {
      this.archiveConversation.emit(conversation);
    });
  }

  protected onTitleBlurInput(value: string) {
    if (this.conversation.title !== value) {
      this.formGroup.valid &&
        this.aiTeacherService.updateConversation(this.conversation.id, { title: value }).subscribe((conversation) => {
          this.finishEditing();
          this.updateConversation.emit(conversation);
        });
    } else {
      this.finishEditing();
    }
  }

  private finishEditing() {
    this.isEditMode = false;

    this.formGroup.patchValue({
      title: "",
    });
  }
}
