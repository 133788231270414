import { ConversationStatus } from "@app/shared/enums/conversation-status-enum";
import { Message } from "@app/shared/models/entities/ai-teacher/Message";
import { User } from "../user/User";
import { Course } from "../course/Course";
import { Training } from "../Training";
import { ExamAttempt } from "@app/exambundle/models/ExamAttempt";
import { UtilService } from "@app/shared/services/util.service";

export class Conversation {
  public id!: number;
  public uuid!: string;
  public title!: string;
  public thread_id!: string;
  public created_at!: string;
  public status!: ConversationStatus;
  public user!: User;
  public messages?: Message[];

  // Additional context
  public course?: Course;
  public training?: Training;
  public exam_attempt?: ExamAttempt;

  public contextTitle: string = "";
  public contextLink: string = "";

  constructor(conversation: Conversation) {
    Object.assign(this, conversation);
  }

  public static Factory(conversation: Conversation) {
    conversation = new Conversation(conversation);
    conversation.setContextTitle();
    conversation.setContextLink();
    return conversation;
  }

  private setContextTitle() {
    const titleParts: string[] = [];

    if (this.training) {
      titleParts.push(this.training.training_name);
    }

    if (this.course) {
      titleParts.push(this.course.course_name);
    }

    if (this.exam_attempt) {
      const examTitle = this.exam_attempt.old_exam
        ? UtilService.getExamTitle(this.exam_attempt.old_exam)
        : UtilService.isSchool24()
          ? "Toets"
          : "Examen";
      titleParts.push(examTitle);
    }

    this.contextTitle = titleParts.join(" - ");
  }

  private setContextLink() {
    if (this.training) {
      this.contextLink = `/vak/${this.training.id}`;
    }
    if (this.course) {
      this.contextLink = `/vak/${this.course.training}/onderwerpen/${this.course.id}`;
    }
    if (this.exam_attempt) {
      this.contextLink = `/vak/${this.exam_attempt.training}/examens/${this.exam_attempt.id}`;
    }
  }
}
