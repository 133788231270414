<p class="font-18 font-weight-500 d-flex justify-content-center danger-color mb-0">
  <span>
    Je kunt maximaal 10 berichten sturen op een gratis account.
    <a
      class="os-text-blue text-decoration-none"
      [href]="link"
    >
      Upgrade naar premium
    </a>
  </span>
</p>
