import { Component } from "@angular/core";
import { UtilService } from "@app/shared/services/util.service";

@Component({
  selector: "chat-content-disclaimer",
  standalone: true,
  templateUrl: "./chat-content-disclaimer.component.html",
  styleUrl: "./chat-content-disclaimer.component.scss",
  imports: [],
})
export class ChatContentDisclaimerComponent {
  protected get link(): string {
    return UtilService.isSchool24() ? "https://www.school24.nl/ai-docent" : "https://www.onlineslagen.nl/ai-docent";
  }
}
