<div class="d-flex gap-3">
  <shared-input
    [group]="formGroup"
    controlName="message"
    placeHolder="Bericht aan Chip..."
    class="flex-grow-1"
    (enterPress)="onSubmit()"
  ></shared-input>

  <shared-button
    faIcon="fa-solid fa-play"
    [style]="'link-blue'"
    [isDisabled]="isDisabled"
    (clicked)="onSubmit()"
  ></shared-button>
</div>
