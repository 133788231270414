@if (selectedConversationId) {
  <div class="d-flex flex-column chat-content-container">
    <div class="os-block-3 p-3 flex-grow-1 overflow-y-auto">
      <chat-message-list [messages]="messages"></chat-message-list>
    </div>

    <div class="position-sticky bottom-0 mt-3">
      <div class="os-block-3 p-3 mb-2">
        @if (isMessagesLimitReached) {
          <chat-message-limit-notification></chat-message-limit-notification>
        } @else if (showRetryMessage) {
          <chat-retry-notification (retry)="onRetryClick()"></chat-retry-notification>
        } @else {
          <chat-input
            [isDisabled]="isDisabledSendButton"
            (sendMessage)="onSendMessage($event)"
          ></chat-input>
        }
      </div>

      <chat-content-disclaimer></chat-content-disclaimer>
    </div>
  </div>
} @else {
  <div class="d-flex flex-column chat-content-container">
    <div class="d-flex flex-column justify-content-center flex-grow-1 chat-input-container">
      <h1 class="font-arvo os-bold d-flex justify-content-center text-center">Waar kan ik mee helpen?</h1>

      <div class="os-block-3 p-3 mb-2">
        <chat-input
          [isDisabled]="isDisabledSendButton"
          (sendMessage)="onCreateConversation($event)"
        ></chat-input>
      </div>
    </div>

    <div class="position-sticky bottom-0 mt-3">
      <chat-content-disclaimer></chat-content-disclaimer>
    </div>
  </div>
}
