import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "chat-retry-notification",
  standalone: true,
  templateUrl: "./chat-retry-notification.component.html",
  styleUrl: "./chat-retry-notification.component.scss",
  imports: [],
})
export class ChatRetryNotificationComponent {
  @Output() retry = new EventEmitter<void>();
}
