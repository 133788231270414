import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";
import { ChatInputComponent } from "@app/ai-teacher/components/chat-input/chat-input.component";
import { ChatMessageListComponent } from "@app/ai-teacher/components/chat-message-list/chat-message-list.component";
import { ChatContentDisclaimerComponent } from "@app/ai-teacher/components/chat-content-disclaimer/chat-content-disclaimer.component";
import { UserService } from "@app/shared/services/user.service";
import { User } from "@app/shared/models/entities/user/User";
import { UtilService } from "@app/shared/services/util.service";
import { AITeacherService } from "@app/shared/services/ai-teacher.service";
import { AITeacherStore } from "@app/shared/stores/ai-teacher-store.service";
import { HttpErrorResponse } from "@angular/common/http";
import { SnackBarService } from "@app/shared/services/utility-services/snack-bar.service";
import { S24Roles } from "@app/shared/enums/s24-roles.enum";
import { OSRoles } from "@app/shared/enums/user-type-enum";
import { ChatMessageLimitNotificationComponent } from "@app/ai-teacher/components/chat-message-limit-notification/chat-message-limit-notification.component";
import { ChatRetryNotificationComponent } from "@app/ai-teacher/components/chat-retry-notification/chat-retry-notification.component";
import { Message } from "@app/shared/models/entities/ai-teacher/Message";

export const FREE_ACCOUNT_MESSAGES_LIMIT = 20;

export interface ChatRequestDetails {
  action: "create" | "send";
  content: string;
}

@Component({
  selector: "chat-content",
  standalone: true,
  templateUrl: "./chat-content.component.html",
  styleUrl: "./chat-content.component.scss",
  imports: [
    ChatMessageListComponent,
    ChatInputComponent,
    ChatContentDisclaimerComponent,
    ChatMessageLimitNotificationComponent,
    ChatRetryNotificationComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatContentComponent implements OnInit, OnDestroy {
  protected messages: Message[] = [];
  protected selectedConversationId?: number;
  protected isConversationsLoading: boolean | null = null;
  protected isDisabledSendButton = false;
  protected showRetryMessage = false;

  private user!: User;
  private newChatMode = false;
  private lastRequestDetails?: ChatRequestDetails;

  private readonly destroyer = new Subject<void>();

  protected get isFreeAccount(): boolean {
    return UtilService.isSchool24()
      ? this.user.user_extended?.s24_role === S24Roles.DemoStudentS24
      : this.user.user_extended?.role === OSRoles.DemoStudent;
  }

  protected get isMessagesLimitReached(): boolean {
    return this.isFreeAccount && this.messages.length >= FREE_ACCOUNT_MESSAGES_LIMIT;
  }

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly userService: UserService,
    private readonly aiTeacherService: AITeacherService,
    private readonly aiTeacherStore: AITeacherStore,
    private readonly snackBarService: SnackBarService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getUserData();
    this.getRouteParams();
  }

  ngOnDestroy(): void {
    this.destroyer.next();
    this.destroyer.complete();
  }

  protected onCreateConversation(value: string) {
    this.newChatMode = true;
    this.isConversationsLoading = null;
    this.isDisabledSendButton = true;
    this.showRetryMessage = false;
    this.selectedConversationId = -1;

    this.lastRequestDetails = { action: "create", content: value };

    this.addMessagePair(value);

    this.aiTeacherService
      .createConversation({ question: value, product_line: UtilService.isSchool24() ? "school24" : "onlineslagen" })
      .pipe(takeUntil(this.destroyer))
      .subscribe({
        next: (chunk) => {
          this.isConversationsLoading === null && this.getConversations();
          this.updateLastMessageContent(chunk);
        },
        error: (error) => {
          this.newChatMode = false;
          this.handleError(error);
        },
        complete: () => {
          this.isDisabledSendButton = false;
          this.cdr.markForCheck();
        },
      });
  }

  protected onSendMessage(value: string) {
    if (this.selectedConversationId) {
      this.isDisabledSendButton = true;
      this.showRetryMessage = false;

      this.lastRequestDetails = { action: "send", content: value };

      this.addMessagePair(value);

      this.aiTeacherService
        .createMessage(this.selectedConversationId, {
          question: value,
          product_line: UtilService.isSchool24() ? "school24" : "onlineslagen",
        })
        .pipe(takeUntil(this.destroyer))
        .subscribe({
          next: (chunk) => this.updateLastMessageContent(chunk),
          error: (error) => this.handleError(error),
          complete: () => {
            this.isDisabledSendButton = false;
            this.cdr.markForCheck();
          },
        });
    }
  }

  protected onRetryClick() {
    if (this.lastRequestDetails) {
      const { action, content } = this.lastRequestDetails;

      this.messages = this.messages.slice(0, -1);

      action === "create" ? this.onCreateConversation(content) : this.onSendMessage(content);
    }
  }

  private addMessagePair(questionText: string) {
    this.messages = [
      ...this.messages,
      Message.Factory({
        sender: "user",
        content: questionText,
        created_at: new Date(),
        name: this.user.first_name,
        avatar: this.user.avatar?.url,
      }),
      Message.Factory({
        sender: "ai",
        content: "",
        created_at: new Date(),
        name: UtilService.getProductChipName(),
        avatar: UtilService.getProductChipAvatar(),
      }),
    ];
  }

  private updateLastMessageContent(chunk: string) {
    const lastMessageIndex = this.messages.length - 1;

    this.messages = [
      ...this.messages.slice(0, lastMessageIndex),
      { ...this.messages[lastMessageIndex], content: this.messages[lastMessageIndex].content + chunk },
    ];

    this.cdr.markForCheck();
  }

  private handleError(error: HttpErrorResponse) {
    const { status, error: responseErrorMessage } = error;

    if (status === 402) {
      this.snackBarService.showErrorMessage(responseErrorMessage);
    } else {
      this.showRetryMessage = true;
      this.messages = this.messages.slice(0, -1);

      this.cdr.markForCheck();
    }
  }

  private getUserData() {
    this.userService
      .getUser()
      .pipe(takeUntil(this.destroyer))
      .subscribe((user) => {
        this.user = user;
      });
  }

  private getRouteParams() {
    this.activatedRoute.paramMap.pipe(takeUntil(this.destroyer)).subscribe((params) => {
      this.isDisabledSendButton = false;
      this.showRetryMessage = false;
      this.lastRequestDetails = undefined;

      if (!this.newChatMode) {
        this.messages = [];
        this.selectedConversationId = +(params.get("id") || 0);
        this.cdr.markForCheck();

        this.getConversation();
      }

      this.newChatMode = false;
    });
  }

  private getConversation() {
    if (this.selectedConversationId) {
      this.aiTeacherService
        .getConversation(this.selectedConversationId)
        .pipe(takeUntil(this.destroyer))
        .subscribe((conversation) => {
          const { messages } = conversation;

          this.messages = (messages || []).map(({ sender, content, created_at }) => ({
            sender,
            content,
            created_at: new Date(created_at || ""),
            name: sender === "ai" ? UtilService.getProductChipName() : this.user.first_name,
            avatar: sender === "ai" ? UtilService.getProductChipAvatar() : this.user.avatar?.url,
          }));
          this.cdr.markForCheck();
        });
    }
  }

  private getConversations() {
    this.isConversationsLoading = true;

    this.aiTeacherService
      .getConversations()
      .pipe(
        takeUntil(this.destroyer),
        finalize(() => {
          this.isConversationsLoading = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe((conversations) => {
        const [lastConversation] = conversations;

        this.selectedConversationId = lastConversation.id;
        this.aiTeacherStore.conversation = lastConversation;

        this.router.navigate(["gesprekken", lastConversation.id]);
      });
  }
}
