<p class="font-18 font-weight-500 d-flex justify-content-center danger-color mb-0">
  <span>
    Er ging iets fout.
    <a
      class="os-text-blue text-decoration-none cursor-pointer"
      (click)="retry.emit()"
    >
      Probeer opnieuw
    </a>
  </span>
</p>
